<template>

  <div class="pages">
    <div class="noData" v-if="!resumesResume">
      <h2>二维码已失效</h2>
      <h2>请重新生产二维码</h2>
    </div>
    <div v-else class="resumePhone">
      <div class="nav">
        <div class="nav_left">
          <div class="left_name">
            <div class="name">
              <span>{{ resumesResume.name }}</span>
            </div>
            <div class="id">
              <span>ID:{{ resumesResume.resumeId }}</span>
            </div>
          </div>
          <div class="person_info">
            <div class="sex">
              <span style="margin-right: 48px;">性别：{{ resumesResume.sexValue }}</span>
              <span>年龄：{{ age }}岁</span>
            </div>
            <div class="tel">
              <span>电话：{{ resumesResume.phone }}</span>
            </div>
            <div class="email">
              <span>邮箱：{{ resumesResume.email }}</span>
            </div>
          </div>
        </div>
        <div class="nav_right">
          <div class="image">
            <img :src="resumesResume.avatar">
          </div>
        </div>
      </div>
      <div class="jobIntention">
        <div class="title_job">
          <i class="iconfont icon-qiuzhiyixiang"></i>
          <span>求职意向</span>
        </div>
        <div class="jobRespect">
          <table style="margin-bottom: 4.27vw;border-radius: 1.33vw" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9" v-for="item in jobIntentions" :key="item.jobIntentionId">
            <tr class="tr_height" style="height: 50px" >
              <td class="td_width" style="border-top-left-radius: 1.33vw">
                <p>期望岗位：</p>
              </td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.positionName }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>行业：</p></td>
              <td class="td_center">
                <p>{{ item.positionCategoryName }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>期望薪资：</p></td>
              <td class="td_center">
                <p>{{ item.maxSalary }}K</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>可接受薪资：</p></td>
              <td class="td_center">
                <p>{{ item.minSalary }}K</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>工作方式：</p></td>
              <td class="td_center">
                <p>{{ item.typeValue }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width" style="border-bottom-left-radius: 1.33vw"><p>工作地址：</p></td>
              <td class="td_center" style="border-bottom-right-radius: 1.33vw">
                <p>{{ item.hopeJobAddrCountry }}/{{item.hopeJobAddrProvince}}/{{item.hopeJobAddrCity }}/{{item.hopeJobAddrRegion}}/{{item.hopeJobAddr }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="workExperience">
        <div class="title_job">
          <i class="iconfont icon-gongzuojingli"></i>
          <span>工作经历</span>
        </div>
        <div class="workDetails">
          <table style="margin-bottom: 4.27vw;border-radius: 1.33vw;" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9" v-for="item in jobExperiences" :key="item.projectExperisenceId">
            <tr class="tr_height">
              <td class="td_width" style="border-top-left-radius: 1.33vw">
                <p>企业：</p>
              </td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.jobCompany  }}</p>
              </td>
            </tr>
            <tr class="tr_height">
              <td class="td_width">
                <p>行业：</p>
              </td>
              <td class="td_center">
                <p>{{ item.profession  }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width">
                <p>岗位：</p>
              </td>
              <td class="td_center">
                <p>{{ item.position }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width">
                <p>工作内容：</p>
              </td>
              <td class="td_center">
                <p>{{ item.jobContent }}</p>
              </td>
            </tr>
            <tr class="tr_height">
              <td class="td_width" style="border-bottom-left-radius: 1.33vw">
                <p>时间：</p>
              </td>
              <td class="td_center" style="border-bottom-right-radius: 1.33vw">
                <p>{{ item.beginDate }} ~ {{item.endDate}}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="projectExperience">
        <div class="title_job">
          <i class="iconfont icon-xiangmu"></i>
          <span>项目经验</span>
        </div>
        <div class="workDetails">
          <table style="margin-bottom: 4.27vw;border-radius: 1.33vw;" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9" v-for="item in projectExperiences" :key="item.uuid">
            <tr class="tr_height" >
              <td class="td_width" style="border-top-left-radius: 1.33vw">
                <p>项目名：</p>
              </td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.name   }}</p>
              </td>
            </tr>
            <tr class="tr_height"  :key="item.uuid">
              <td class="td_width"><p>项目介绍：</p></td>
              <td class="td_center">
                <p>{{ item.introduce }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width" style="border-bottom-left-radius: 1.33vw"><p>项目职责：</p></td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.responsibility }}</p>
              </td>
            </tr>
            <!--          <tr class="tr_height" v-for="item in projectExperiences" :key="item.uuid" v-show="projectExperience.startTime||projectExperience.endTime">-->
            <!--            <td class="td_width">起始时间：</td>-->
            <!--            <td class="td_center">-->
            <!--              <p>{{ item.startTime }} ~ {{item.endTime }}</p>-->
            <!--            </td>-->
            <!--          </tr>-->
          </table>
        </div>
      </div>
      <div class="educationExperience">
        <div class="title_job">
          <i class="iconfont icon-jiaoyu"></i>
          <span>教育经历</span>
        </div>
        <div class="workDetails">
          <table style="margin-bottom: 4.27vw;border-radius: 1.33vw;" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9" v-for="item in educationExperiences" :key="item.educationExperienceId">
            <tr class="tr_height" >
              <td class="td_width" style="border-top-left-radius: 1.33vw">
                <p>学校：</p>
              </td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.school  }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>专业：</p></td>
              <td class="td_center">
                <p>{{ item.major  }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>取得学历：</p></td>
              <td class="td_center">
                <p>{{ item.qualification }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>校内经历：</p></td>
              <td class="td_center">
                <p>{{ item.exp }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width" style="border-bottom-left-radius: 1.33vw"><p>在校时间：</p></td>
              <td class="td_center" style="border-bottom-right-radius: 1.33vw">
                <p>{{ item.admissionDate }} ~ {{item.graduationDate }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="certificate" v-if="resumeCredentials.length">
        <div class="title_job">
          <i class="iconfont icon-zhengshu"></i>
          <span>证书</span>
        </div>
        <div class="workDetails">
          <table style="margin-bottom: 4.27vw;border-radius: 1.33vw;" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9" v-for="item in resumeCredentials" :key="item.credentialId">
            <tr class="tr_height" >
              <td class="td_width" style="border-top-left-radius: 1.33vw">
                <p>证书名：</p>
              </td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.name }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width"><p>证书分类：</p></td>
              <td class="td_center">
                <p>{{ item.credentialSortName  }}</p>
              </td>
            </tr>
            <tr class="tr_height" >
              <td class="td_width" style="border-bottom-left-radius: 1.33vw"><p>证书介绍：</p></td>
              <td class="td_center" style="border-bottom-right-radius: 1.33vw">
                <p>{{ item.introduce }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="resumesResume.honor"  class="honor">
        <div class="title_job">
          <i class="iconfont icon-rongyu"></i>
          <span>荣誉奖项</span>
        </div>
        <div class="honor_content">
          {{ resumesResume.honor }}
        </div>
      </div>
      <div class="self_description"  v-if="descriptionTag.length">
        <div class="title_job">
          <i class="iconfont icon-comment-w"></i>
          <span>自我描述</span>
        </div>
        <div class="self_details">
          <p v-for="tag in descriptionTag" :key="tag.tagId">
            <span>{{tag.tagContent}}</span>
          </p>
        </div>
      </div>
      <div class="hobbiesInterests" v-if="resumesResume.hobby">
        <div class="title_job">
          <i class="iconfont icon-xingquaihao"></i>
          <span>兴趣爱好</span>
        </div>
        <div class="hobbies_content">
          <table style="margin-bottom: 4.27vw;border-radius: 1.33vw;" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9">
            <tr >
              <td style="border-radius: 1.33vw">
                <p>{{ resumesResume.hobby }}</p>
              </td>
            </tr>
          </table>

        </div>
      </div>
      <div class="interviewRecords">
        <div class="title_job">
          <i class="iconfont icon-xingquaihao"></i>
          <span>面试记录</span>
        </div>
        <div v-for="item in interviewRecords" :key="item.uuid" class="workDetails">
          <table  style="margin-bottom: 4.27vw;border-radius: 1.33vw" width="100%" cellspacing="1" cellpadding="" bgcolor="#eae9e9">
            <tr class="tr_height">
              <td class="td_width" style="border-top-left-radius: 1.33vw">
                <p>面试官：</p>
              </td>
              <td class="td_center" style="border-top-right-radius: 1.33vw">
                <p>{{ item.interviewer }}</p>
              </td>
            </tr>
            <tr class="tr_height">
              <td class="td_width">
                <p>结果：</p>
              </td>
              <td class="td_center">
                <p>{{ item.isPassStr }}</p>
              </td>
            </tr>
            <tr class="tr_height">
              <td class="td_width">
                <p>面试时间：</p>
              </td>
              <td class="td_center">
                <p>{{ item.interviewTime }}</p>
              </td>
            </tr>
            <tr class="tr_height">
              <td class="td_width" style="border-bottom-left-radius: 1.33vw">
                <p>面试评价：</p>
              </td>
              <td class="td_center" style="border-bottom-right-radius: 1.33vw">
                <p>{{ item.interviewEvaluate }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {getResumeDetailInfo} from "@/api/resumePhone";

export default {
  name: 'resumePhone',
  data() {
    //这里存放数据,
    return {
      params: {
        qrShareRecordId: null
      },
      credentialNameString: null,
      customer: null,
      descriptionTag: null,
      educationExperiences: null,
      emergencyContact: null,
      interviewRecords: null,
      jobExperiences: null,
      jobIntentions: null,
      projectExperiences: null,
      recordTags: null,
      resumeCredentials: null,
      resumeFamilyMembers: null,
      resumeTitleInfo: null,
      resumesResume: null,
      userLanguages: null,

      age: null,
    };
  },
  methods: {
    getInfo() {
      console.log(this.params, "1")
      getResumeDetailInfo(this.params).then(res => {
        console.log(res, "2")
        if (res.data.code === 200) {
          this.credentialNameString = res.data.data.credentialNameString
          this.customer = res.data.data.customer
          this.descriptionTag = res.data.data.descriptionTag
          this.educationExperiences = res.data.data.educationExperiences
          this.emergencyContact = res.data.data.emergencyContact
          this.interviewRecords = res.data.data.interviewRecords
          this.jobExperiences = res.data.data.jobExperiences
          this.jobIntentions = res.data.data.jobIntentions
          this.projectExperiences = res.data.data.projectExperiences
          this.recordTags = res.data.data.recordTags
          this.resumeCredentials = res.data.data.resumeCredentials
          this.resumeFamilyMembers = res.data.data.resumeFamilyMembers
          this.resumeTitleInfo = res.data.data.resumeTitleInfo
          this.resumesResume = res.data.data.resumesResume
          this.userLanguages = res.data.data.userLanguages

          this.getAge(this.resumesResume.birthday)
        }
      })
    },
    /** 获取到页面参数 */
    getQueryParam() {
      const query = location.search.substring(1)
      let arr = query.split("=");
      let qrShareRecordId = arr[1]
      this.params.qrShareRecordId = Number.parseInt(qrShareRecordId)
    },
    getAge(birthday) {//根据日期算年龄
      if (birthday) {
        birthday = birthday.split('-');
        // 新建日期对象
        let date = new Date();
        // 今天日期，数组，同 birthday
        let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
        // 分别计算年月日差值
        let age = today.map((val, index) => {
          return val - birthday[index]
        })
        // 当天数为负数时，月减 1，天数加上月总天数
        if (age[2] < 0) {
          // 简单获取上个月总天数的方法，不会错
          let lastMonth = new Date(today[0], today[1], 0)
          age[1]--
          age[2] += lastMonth.getDate()
        }
        // 当月数为负数时，年减 1，月数加上 12
        if (age[1] < 0) {
          age[0]--
          age[1] += 12
        }
        // console.log(age[0] + '岁' + age[1] + '月' + age[2] + '天');
        this.age = age[0]
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前 this 实例）,
  created() {
    this.getQueryParam()
    this.getInfo()
  },
}
</script>

<style lang="scss" scoped>
.pages {

  table {
    tr {
      td {
        //border: 1px solid #12ACF2;
        background-color: #fff;
      }
    }
  }
  .resumePhone {
    .nav {
      padding: 3.2vw 3.2vw 0 3.2vw;
      display: flex;
      justify-content: space-between;
      .nav_left {
        display: flex;
        flex-direction: column;
        .left_name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 1.25rem;
            color: #12ACF2;
          }
          .id {
            font-size: 1.125rem;
            color: #12ACF2;
          }
        }

        .person_info {
          margin-top: 4.27vw;
          .sex {

          }
          .tel {
            margin-top: 2.13vw;
          }
          .email {
            margin-top: 2.13vw;
          }
        }
      }
      .nav_right {
        .image {

          width: 26.67vw;

          img {
            width: 100%;
          }
        }
      }
    }
    .work_info {
      margin-top: 3.2vw;
      background-color: #12ACF2;
      padding: 3.2vw;
      border-radius: 2.13vw;

    }
    .jobIntention {
      width: 100%;
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .jobRespect {
        .tr_height {
          height: 10.67vw;

          .td_width {
            width: 26.67vw;
            color: #12ACF2;
            padding-left: 8px;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
          .td_center {
            line-height: 6.4vw;
            padding-left: 8px;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
        }
      }
    }
    .workExperience {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .workDetails {
        .tr_height {
          height: 10.67vw;
          //display: flex;
          //justify-content: center;
          //align-items: center;

          .td_width {
            width: 26.67vw;
            color: #12ACF2;
            line-height: 10.67vw;
            padding-left: 8px;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
          .td_center {
            line-height: 10.67vw;
            padding-left: 8px;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
        }
      }
    }
    .projectExperience {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .workDetails {
        .tr_height {
          height: 10.67vw;
          //display: flex;
          //justify-content: center;
          //align-items: center;

          .td_width {
            width: 26.67vw;
            color: #12ACF2;
            line-height: 10.67vw;
            padding-left: 8px;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
          .td_center {
            line-height: 10.67vw;
            padding-left: 8px;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
        }
      }
    }
    .educationExperience {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .workDetails {
        .tr_height {
          height: 10.67vw;
          //display: flex;
          //justify-content: center;
          //align-items: center;

          .td_width {
            width: 26.67vw;
            color: #12ACF2;
            line-height: 10.67vw;
            padding-left: 2.13vw;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
          .td_center {
            line-height: 10.67vw;
            padding-left: 2.13vw;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
        }
      }
    }
    .certificate {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .workDetails {
        .tr_height {
          height: 10.67vw;
          //display: flex;
          //justify-content: center;
          //align-items: center;

          .td_width {
            width: 26.67vw;
            color: #12ACF2;
            line-height: 10.67vw;
            padding-left: 2.13vw;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
          .td_center {
            line-height: 10.67vw;
            padding-left: 2.13vw;
            p {
              margin: 0;
              line-height: 4.8vw;
            }
          }
        }
      }
    }
    .honor {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 5.87vw;
          margin-right: 1.6vw;
        }
      }
    }
    .self_description {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
    }
    .hobbiesInterests {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .hobbies_content {
        margin-top: 2.13vw;
        //padding-left: 2.13vw;
        p {
          padding-left: 1.6vw;
        }
      }
    }
    .interviewRecords {
      .title_job {
        margin-top: 3.2vw;
        color: #12ACF2;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        //border-bottom: 0.53vw solid #12ACF2;
        padding-bottom: 1.6vw;
        .iconfont {
          font-size: 1.375rem;
          margin-right: 1.6vw;
        }
      }
      .tr_height {
        height: 10.67vw;
        //display: flex;
        //justify-content: center;
        //align-items: center;

        .td_width {
          width: 26.67vw;
          color: #12ACF2;
          line-height: 10.67vw;
          padding-left: 2.13vw;
          p {
            margin: 0;
            line-height: 4.8vw;
          }
        }
        .td_center {
          line-height: 10.67vw;
          padding-left: 2.13vw;
          p {
            margin: 0;
            line-height: 4.8vw;
          }
        }
      }
    }

  }
  .noData {
    width: 100vw;
    height: 135vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>