import request from "@/utils/request.js"

// 查询小程序横幅管理界面列表
export function getResumeDetailInfo(query) {
    return request({
        url: 'https://api.youmaijob.com/recruit/resume/getResumeDetail/fromPhone',
        method: 'get',
        params: query
    })
}
