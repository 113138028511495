<template>
  <div id="app">
    <resumePhone/>
  </div>

</template>

<script>
import resumePhone from "@/components/resumePhone";
export default {
  name: 'App',
  components: {
    resumePhone
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}
</style>
